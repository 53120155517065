import React from 'react'
import Iframe from 'react-iframe';
import {Column1, Column2, Heading, ImgWrap, InfoContainer, InfoRow, InfoWrapper, Subtitle, TextWrapper, TopLine} from './InfoElements'

const Info = ({lightBg, lightText, darkText}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id='info'>
                <InfoWrapper>
                    <InfoRow>

                        <Column1>
                            <TextWrapper>
                                <Heading lightText={lightText}>Kontakt</Heading>
                                <Subtitle darkText={darkText}>Adresse:</Subtitle>
                                <TopLine>Britzer Damm 94</TopLine>
                                <TopLine>12347 Berlin</TopLine>
                                <Subtitle darkText={darkText}>Telefon:</Subtitle>
                                <TopLine>0159 010 670 42</TopLine>
                                <Subtitle darkText={darkText}>E-Mail:</Subtitle>
                                <TopLine>info@gutschkeglas.de</TopLine>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2431.388545989216!2d13.43703714827223!3d52.45399035529976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84f7f97eab7d1%3A0xd450e8bb810a9170!2sBritzer%20Damm%2094%2C%2012347%20Berlin!5e0!3m2!1sde!2sde!4v1648109867335!5m2!1sde!2sde"
                                    width="600" height="450" style={{"border":"0"}} allowFullScreen="" loading="lazy"/>
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default Info
