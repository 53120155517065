import React from "react";
import Video from '../../videos/video.mp4'
import { BerlinBg, BerlinContainer, BerlinContent, BerlinH1, BerlinP, VideoBg} from './BerlinElements'

const Berlin = () => {

    return (
        <BerlinContainer id='Berlin'>
            <BerlinBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
            </BerlinBg>
            <BerlinContent>
                <BerlinH1>Glasermeister Jan-Philipp Gutschke</BerlinH1>
                <BerlinP/>
            </BerlinContent>
        </BerlinContainer>
    )
}

export default Berlin
