import React, {useState} from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Berlin from "../components/Berlin";
import Services from "../components/Services";
import Footer from "../components/Footer";
import Info from "../components/Info";
import {homeObjOne} from "../components/Info/Data";

const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <Berlin/>
            <Services/>
            <Info{...homeObjOne}/>
            <Footer/>
        </>
    );
};

export default Home;

