import React from 'react'
import {
    FooterContainer,
    FooterWrap,
    FooterLinkWrapper,
    FooterLinkItems,
    FooterLinkContainer,
    FooterLinkTitle,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights,
    FooterOpening, FooterAddress
} from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';
import Logo from "../../images/Gutschke.png";
import {Img} from "../Navbar/NavbarElements";

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinkContainer>
                    <FooterLinkWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Öffnungszeiten</FooterLinkTitle>
                            <FooterOpening>Nach Absprache</FooterOpening>
                            <FooterOpening>Rufen Sie uns gerne an</FooterOpening>
                            {/*<FooterOpening>Mittwoch</FooterOpening>*/}
                            {/*<FooterOpening>Donnerstag</FooterOpening>*/}
                            {/*<FooterOpening>Freitag</FooterOpening>*/}
                            {/*<FooterOpening>Samstag</FooterOpening>*/}
                            {/*<FooterOpening>Sonntag</FooterOpening>*/}
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Wo Sie uns finden</FooterLinkTitle>
                            <FooterAddress>Britzer Damm 94</FooterAddress>
                            <FooterAddress>12347 Berlin</FooterAddress>
                            <FooterAddress>Telefon: 0159 010 670 42</FooterAddress>
                        </FooterLinkItems>
                    </FooterLinkWrapper>
                    <FooterLinkWrapper>
                    </FooterLinkWrapper>
                </FooterLinkContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            <Img src={Logo}/>
                        </SocialLogo>
                        <WebsiteRights>Gutschke Glas © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer