import React from 'react'

import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesH2, ServicesP} from './ServiceElements'

const Services = () => {
    return (
        <ServicesContainer id='services'>
            <ServicesH1>Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesH2>Wohnraumgestaltung</ServicesH2>
                    <ServicesP>• Duschaufsätze</ServicesP>
                    <ServicesP>• Badewannenaufsätze</ServicesP>
                    <ServicesP>• Glastürenanlagen</ServicesP>
                    <ServicesP>• Glaspanele für Küchen</ServicesP>
                    <ServicesP>• Ganzglasanlagen</ServicesP>
                    <ServicesP>• Glasplatten</ServicesP>
                    <ServicesP>• Spiegelanfertigung nach Maß</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesH2>Wohnraumoptimierung</ServicesH2>
                    <ServicesP>• Schallschutzglas</ServicesP>
                    <ServicesP>• Sicherheitsglas</ServicesP>
                    <ServicesP>• Lärmschutz</ServicesP>
                    <ServicesP>• Hitzebeständiges Glas</ServicesP>
                    <ServicesP>• Wärme- und</ServicesP>
                    <ServicesP>Sonnenschutzglas</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesH2>      Bauglaserei      </ServicesH2>
                    <ServicesP>• Schaufensterverglasung</ServicesP>
                    <ServicesP>• Sicherheitsverglasung</ServicesP>
                    <ServicesP>• Einbruchshemmende</ServicesP>
                    <ServicesP>Verglasung</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services


